import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import pbiLogo from '../static/image/pbi_logo.svg'

const demoList = [
  {
    name: 'Family Day Care',
    urlHead: 'family-day-care',
    description:
      'Analysis of supply and demand dummy data to identify geographic growth opportunities'
  },
  {
    name: 'In-home Aged Care',
    urlHead: 'in-home-aged-care',
    description:
      'Analysis of supply and demand dummy data to identify geographic growth opportunities'
  },
  {
    name: 'Georges River University',
    urlHead: 'gru',
    description:
      'University engagement for a hypothetical Australian university'
  },

  {
    name: 'Disability Services',

    urlHead: 'disabilityservices',
    description: 'Analysis of Australian disability service public domain data'
  },

  {
    name: 'Market Share',

    urlHead: 'market-share',
    description:
      'Analysis of market penetration and opportunity for a hypothetical bank in Australia'
  },

  {
    name: 'Performance Reporting',
    urlHead: 'performance-reporting',
    description:
      "Analysis of NSW regions' performance against hypothetical NSW government targets regarding drug induced deaths and hospitalisations"
  },

  {
    name: 'Competitor Analysis',
    urlHead: 'competitor-analysis',
    description:
      'Analysis of competitors for a hypothetical community health provider in Australia'
  },

  {
    name: 'KWA',
    urlHead: 'kwa',
    description:
      'Analysis of membership status by CED and SED for a hypothetical welfare association in Australia'
  },

  {
    name: 'Lite',
    urlHead: 'lite',
    description:
      'Analysis of priorities, stats, grants, LGA partners and university partners for LGAs'
  },

  {
    name: 'ADF Community',
    urlHead: 'adf-community',
    description:
      'Analysis of the Australian Defence Force community'
  },

  {
    name: 'Careers',
    urlHead: 'careers',
    description:
      'Analysis of higher education completion and employment'
  },
  {
    name: 'Utopia',
    urlHead: 'utopia',
    description:
      'Integrated analysis of SDA related data across different boundary types'
  }
]
const DemoList = () => {
  const openReport = (urlHead: string) =>
    window.open(`https://${urlHead}.commpact.com.au`, '_blank')
  return (
    <Grid container width="100%" my={1}>
      <Grid item xs={11}>
        <Typography paddingLeft={1} variant="subtitle1">
          Demos
        </Typography>
        <List sx={{ width: '100%', paddingY: 0 }}>
          {demoList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((demo) => (
              <>
                <ListItem
                  key={demo.name}
                  sx={{ padding: 0, borderTop: '1px solid lightgrey' }}
                >
                  <ListItemButton onClick={() => openReport(demo.urlHead)}>
                    <ListItemIcon>
                      <Box
                        component={'img'}
                        src={pbiLogo}
                        alt="Logo"
                        maxHeight={'32px'}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle2">{demo.name}</Typography>
                      }
                      secondary={demo.description}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            ))}
        </List>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default DemoList

import "./App.css";

import "powerbi-report-authoring";
import AuthorisedPowerBiReport from "./components/AuthorisedPowerBiReport";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import LoginWaitScreen from "./components/LoginWaitScreen";
import Layout from "./components/Layout";
import DemoList from "./components/DemoList";
import { useIsAuthenticated } from "@azure/msal-react";
import { FunctionComponent } from "react";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/login",
          element: <LoginWaitScreen />,
        },
        {
          path: "/",
          element: (
            <ProtectedRoute>
              <>
                <DemoList />
              </>
            </ProtectedRoute>
          ),
        },
        {
          path: "/*",
          element: (
            <ProtectedRoute>
              <>
                <DemoList />
              </>
            </ProtectedRoute>
          ),
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
const ProtectedRoute: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const auth = useIsAuthenticated();
  return auth ? children : <Navigate to="/login" />;
};
export default App;

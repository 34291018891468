export const refreshTime = 1000 * 60 * 20;
// export const groupId = process.env.GROUP_ID as string;
// export const clientId = process.env.CLIENT_ID as string;
// export const reportId = process.env.REPORT_ID as string;
// export const baseUrl = "http://localhost:7071/api";

// Test configs
// export const groupId = "875b7269-afbd-4ebb-ba9a-ee921f334e89";
// const clientId = "1b00a4e6-8e59-472a-93ab-8a0c945d84c4";
// export const baseUrl = "https://commpact-backend.azurewebsites.net/api";
// const tenantId = "f5317a63-3c05-4e44-9815-f9ed388d3420";
// export const hostKey =
//   "ZYMrbhFtpkiIypwgHpTUPZ0X6ZFpZWqrohEclpZXplM_AzFu_xwFkg==";

// Prod configs
export const groupId = "bbe43fe4-e460-4c7e-8c0a-3e929a788e69";
const clientId = "9e222363-3718-4b41-921a-ce74a60761ea";
export const baseUrl = "https://commpact-backend.azurewebsites.net/api";
const tenantId = "f5317a63-3c05-4e44-9815-f9ed388d3420";
export const hostKey =
  "ZYMrbhFtpkiIypwgHpTUPZ0X6ZFpZWqrohEclpZXplM_AzFu_xwFkg==";

export const msalConfig = {
  auth: {
    clientId,
    authority: "https://login.microsoftonline.com/" + tenantId, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
